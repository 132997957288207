import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Waypoint } from "react-waypoint"

import MetaTitle from "src/components/MetaTitle"
import Layout from "src/components/Layout"
import { Box } from "src/style"

import WorkThumb from "src/components/WorkThumb"

const Grid = styled(Box).attrs({
  pt: [9, 10],
  px: [9, 9, 5, 9],
})`
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  flex-basis: 100%;
  align-items: flex-end;

  &:after {
    content: "";
    width: 25%;
    padding: 0 ${props => props.theme.space[8]};
  }
`

const PAGE_SIZE = 22

export default ({ data: { projects, navigation_color, background_color } }) => {
  const themeName = navigation_color === "BLACK" ? "light" : "dark"
  const defaultBg = navigation_color === "BLACK" ? "#fff" : "#000"
  const bgColor = background_color ? background_color : defaultBg

  const [totalLoaded, setTotalLoaded] = useState(PAGE_SIZE)
  const paginatedProjects = projects.slice(0, totalLoaded)

  return (
    <Layout themeName={themeName} bgColor={bgColor}>
      <MetaTitle title="Work" />
      <Grid>
        {paginatedProjects.map(project => {
          return <WorkThumb key={project.id} project={project.project} />
        })}
      </Grid>
      {totalLoaded < projects.length && (
        <Waypoint
          bottomOffset="-600px"
          onEnter={() => setTotalLoaded(totalLoaded + PAGE_SIZE)}
        />
      )}
    </Layout>
  )
}

export const workGridQuery = graphql`
  fragment WorkGrid on PrismicWorkGridData {
    background_color
    navigation_color
    projects {
      project {
        id
        document {
          ...WorkThumb
        }
      }
    }
  }
`
