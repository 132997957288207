import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { Text, Box, Year, A, theme } from "src/style"

const Container = styled(Box).attrs({
  justifyContent: "center",
  alignItems: "center",
})`
  flex-basis: 100%;
  text-align: center;
  padding-top: 6em;
  padding-bottom: 0.5em;

  ${theme.mediaQueries.small} {
    flex-basis: 50%;
  }

  ${theme.mediaQueries.medium} {
    flex-basis: 33%;
    padding-top: 9em;
  }

  ${theme.mediaQueries.large} {
    flex-basis: 33%;
  }

  ${theme.mediaQueries.fifteeninches} {
    flex-basis: 25%;
  }
`

const AThumb = styled(A)`
  display: block;
  transition: all 0.3s ease-out;
  backface-visibility: hidden;

  &:hover img,
  &:hover iframe {
    transform: scale(1.028);
    transition: all 0.3s ease-out;
    backface-visibility: hidden;
    transform-origin: center;
    cursor: pointer;
  }
`

const Caption = styled(Box).attrs({
  display: "flex",
  flexDirection: "row",
  alignItems: "start",
  justifyContent: "center",
})`
  height: 4em;
  padding: 0 0.5em;
`

const Title = styled(Text).attrs({
  fontFamily: "serif",
  fontSize: "h4",
  lineHeight: 1.11,
})`
  margin-left: -0.5em;

  ${theme.mediaQueries.fifteeninches} {
    fontsize: "h5";
  }
`

const Iframe = styled.iframe.attrs({
  frameborder: "0",
  webkitallowfullscreen: true,
  mozallowfullscreen: true,
  allowfullscreen: true,
})`
  height: 180px;
  width: 100%;
  border: 0;
  margin: 0 auto 0.5em;
  transition: all 0.3s ease-out;
  backface-visibility: hidden;
  pointer-events: none;

  ${theme.mediaQueries.medium} {
    height: 206px;
  }
`

const Img = styled(Box)`
  margin: 0 auto ${props => props.theme.space[5]};
  height: 180px;
  width: auto;
  background-image: url(${props => props.src});
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.3s ease-out;
  backface-visibility: hidden;

  ${theme.mediaQueries.medium} {
    height: 206px;
  }

  &:hover {
    transform: scale(1.028);
    transform-origin: center;
    backface-visibility: hidden;
  }

  ${props =>
    props.portrait &&
    `
    height: 260px;

    ${theme.mediaQueries.medium} {
      height: 285px;
    }
  `}
`

const WorkThumb = ({ project: { document } }) => {
  if (!document || document.length === 0) {
    return null
  }

  const { data } = document[0]
  const { image } = data
  const video_id = data.work_video && data.work_video.video_id

  if (video_id) {
    const src = `https://player.vimeo.com/video/${video_id}?background=1&autoplay=1&loop=1&byline=0&title=0`
    return (
      <Container>
        <AThumb to={`/work/${document[0].uid}`}>
          <Iframe src={src} />
          <Caption>
            <Title>
              <Year px={3} mr={5}>
                {data.label && data.label.text}
              </Year>
              {data.title.text}
            </Title>
          </Caption>
        </AThumb>
      </Container>
    )
  }

  if (!image || !image.responsive_mobile || !image.responsive_mobile.url) {
    return null
  }

  return (
    <Container>
      <A to={`/work/${document[0].uid}`}>
        {image && image.dimensions && image.responsive_mobile && (
          <Img
            portrait={image.dimensions.height > image.dimensions.width}
            src={image.responsive_mobile.url}
          />
        )}
        <Caption>
          <Title>
            <Year px={3} mr={5}>
              {data.label && data.label.text}
            </Year>
            {data.title.text}
          </Title>
        </Caption>
      </A>
    </Container>
  )
}

export default WorkThumb

export const projectQuery = graphql`
  fragment WorkThumb on PrismicProject {
    __typename
    id
    uid
    data {
      client {
        document {
          data {
            name {
              html
              text
            }
          }
        }
      }

      image: cover_image {
        dimensions {
          width
          height
        }
        responsive_mobile {
          url
        }
      }

      work_video {
        video_id
      }

      label {
        text
      }
      title {
        text
      }
    }
  }
`
